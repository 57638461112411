import cn from 'classnames';
import styles from './Ghost.module.scss';
import { useState } from 'react';

type GhostProps = {
  className?: string;
};

export function Ghost({ className }: GhostProps) {
  const [clicked, setClicked] = useState(false);
  const wrapperClassName = cn(
    styles.wrapper,
    {
      [styles.clicked]: clicked,
    },
    className,
  );

  const handleClick = () => {
    if (clicked) return;
    setClicked(true);
    setTimeout(() => setClicked(false), 2000);
  };

  return (
    <div className={wrapperClassName} onClick={handleClick}>
      <img src="/webp/like.webp" alt="like" className={styles.like} />
      <img src="/webp/lapa.webp" alt="hand" className={styles.hand} />
      <img src="/webp/ghost.webp" alt="ghost" className={styles.ghost} />
    </div>
  );
}
