import cn from 'classnames';
import styles from './AboutMe.module.scss';
import { Title } from '@/components/Title';
import { useTranslations } from 'next-intl';
import { SlideText } from '@/components/SlideText';
import { useState } from 'react';
import { Button } from '@/components/Button';
import { useMobileWidth } from '@/hooks/useMobileWidth';
import { FramerBlock } from '@/components/FramerBlock';

type AboutMeProps = {
  className?: string;
};

export function AboutMe({ className }: AboutMeProps) {
  const wrapperClassName = cn(styles.wrapper, className);
  const [blockText, setBlockText] = useState('Приложение');
  const t = useTranslations();
  const isMobile = useMobileWidth();

  const handleSliderChange = (item: string) => {
    switch (item) {
      case t('about.build'):
        setBlockText(t('about.architecture'));
        return;
      case t('about.discover'):
        setBlockText(t('about.task'));
        return;
      case t('about.setUp'):
        setBlockText(t('about.project'));
        return;
      case t('about.lead'):
        setBlockText(t('about.team'));
        return;
      default:
        setBlockText(t('about.app'));
        return;
    }
  };

  const scrollToContacts = () => {
    const element = document
      .getElementById('contacts')
      ?.getBoundingClientRect();
    if (element) {
      const position = window.scrollY + element.top;
      window.scrollTo({
        top: position - (isMobile ? 30 : 140),
        behavior: 'smooth',
      });
    }
  };

  return (
    <div className={wrapperClassName} id="about">
      <div className={styles.info}>
        <Title text={t('about.title')} className={styles.title} />
        <img src="/webp/gamepad.webp" alt="gamepad" className={styles.img} />
        <div className={styles.empty} />
      </div>
      <div className={styles.content}>
        <div className={styles.top}>
          <FramerBlock
            transition={0.6}
            variants={{
              visible: { transform: 'translateY(0%)', opacity: 1 },
              hidden: { transform: 'translateY(50%)', opacity: 0 },
            }}
            className={styles.topText}
          >
            {t.rich('about.general', {
              br: () => <br />,
              nbsp: () => <>&nbsp;</>,
            })}
          </FramerBlock>
          <div className={styles.topBlocks}>
            <FramerBlock
              transition={0.6}
              variants={{
                visible: { transform: 'translateY(0%)', opacity: 1 },
                hidden: { transform: 'translateY(50%)', opacity: 0 },
              }}
              className={styles.block}
            >
              {t('name')} {t('surname')}
            </FramerBlock>
            <FramerBlock
              transition={0.9}
              variants={{
                visible: { transform: 'translateY(0%)', opacity: 1 },
                hidden: { transform: 'translateY(50%)', opacity: 0 },
              }}
              className={styles.block}
            >
              {t('about.city')}
            </FramerBlock>
            <FramerBlock
              transition={1.2}
              variants={{
                visible: { transform: 'translateY(0%)', opacity: 1 },
                hidden: { transform: 'translateY(50%)', opacity: 0 },
              }}
              className={cn(styles.block, styles.blockBlack)}
            >
              Frontend x Fullstack
            </FramerBlock>
          </div>
        </div>
        <FramerBlock
          transition={1.2}
          variants={{
            visible: { transform: 'translateY(0%)', opacity: 1 },
            hidden: { transform: 'translateY(50%)', opacity: 0 },
          }}
          className={cn(styles.block, styles.blockBlack, styles.blockSmall)}
        >
          {isMobile ? t('about.expMobile') : t('about.exp')}
        </FramerBlock>
        <div className={styles.bottom}>
          <div className={styles.bottomBlocks}>
            <FramerBlock
              transition={0.6}
              variants={{
                visible: { transform: 'translateY(0%)', opacity: 1 },
                hidden: { transform: 'translateY(50%)', opacity: 0 },
              }}
              className={styles.slider}
            >
              <SlideText
                items={[
                  t('about.develop'),
                  t('about.discover'),
                  t('about.build'),
                  t('about.lead'),
                  t('about.create'),
                  t('about.fix'),
                  t('about.setUp'),
                  t('about.improve'),
                ]}
                timer={2000}
                onChange={handleSliderChange}
              />
            </FramerBlock>
            <FramerBlock
              transition={0.9}
              variants={{
                visible: { transform: 'translateY(0%)', opacity: 1 },
                hidden: { transform: 'translateY(50%)', opacity: 0 },
              }}
              className={styles.blockButton}
            >
              <Button
                text={blockText}
                onClick={scrollToContacts}
                className={styles.button}
              />
            </FramerBlock>
          </div>
          <FramerBlock
            transition={1.2}
            variants={{
              visible: { transform: 'translateY(0%)', opacity: 1 },
              hidden: { transform: 'translateY(50%)', opacity: 0 },
            }}
            className={styles.bottomMain}
          >
            <div className={styles.bottomText}>
              {t.rich('about.additional', {
                br: () => <br />,
                nbsp: () => <>&nbsp;</>,
              })}
            </div>
          </FramerBlock>
        </div>
      </div>
    </div>
  );
}
